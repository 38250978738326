.control-group {
    margin-bottom: 10px;
}

.control-group .control-input {
    width: 100%;
}

.control-title {
    font-weight: 600;
}

.date-picker {
    margin-left: 5px;
    margin-right: 10px;
}

button {
    margin-bottom: 10px;
}

ul.itemList {
    list-style-type: none;
    padding-left: 0;
}

    ul.itemList li:first-child {
        background-color: lightblue;
    }

ul li label input {
    margin-right: 5px;
}

.alert-msg {
    margin-top: 10px;
}