#blur {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 100px;
    height: 100%;
    width: 100%;
    z-index: 999;
    cursor: wait;
    background-color: rgb(204, 194, 194, 0.5)
}
#filter {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 100px;
    height: 100%;
    width: 100%;
    z-index: 998;
    background-color: rgb(204, 194, 194, 0.5)
}
.center-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}
.small-table {
    font-size: smaller;
}

.pointer:hover{
    cursor:pointer
}
th {
    background: white;
    position: sticky;
    top: 0; /* Don't forget this, required for the stickiness */
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}
.rotate {
    transform: rotate(-180deg);
    width: 30px;
    height: 60px;
    writing-mode: vertical-rl;
    margin-left: 1px;
}
.lowCell {
    background-color: lightyellow !important;
}
.missingCell {
    background-color: salmon !important;
}